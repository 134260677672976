<template>
  <div id="more-tickets" :class="{ sidebar: isSidebar }">
    <Tickets ref="ticket" />
  </div>
</template>

<script>
import Tickets from '@/components/pages/landings/MobileLandingTickets.vue';

export default {
  data() {
    return {
      headerVisible: null,
    };
  },

  components: {
    Tickets,
  },

  computed: {
    isSidebar() {
      return (
        (this.$store.state.config.isTouchDevice && ['Stores', 'StoreSingle'].includes(this.$route.name)) ||
        !this.$store.state.config.isTouchDevice
      );
    },
  },

  watch: {
    headerVisible: {
      handler(value) {
        const $siteHeaderTitle = $('#site-header > .container > .header > .title');
        const $siteHeader = $('#site-header');

        if (value === true) {
          $siteHeaderTitle.removeClass('off');
          $siteHeader.addClass('shadow');
        } else if (value === false) {
          $siteHeaderTitle.addClass('off');
          $siteHeader.removeClass('shadow');
        }
      },
    },
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $(this.$refs.ticket.$refs.title);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        this.headerVisible = false;
      } else {
        this.headerVisible = true;
      }
    },
  },
};
</script>
